import React, { useState } from "react";
import { ArrowSmDownIcon } from "@heroicons/react/outline";
import ZohoCampaignItinerary from "./ZohoCampaignItinerary";

const ItineraryPopupForm = ({ closePopup, handleSubmit }) => {
	const [email, setEmail] = useState("");

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const handleFormSubmit = () => {
		// Perform any email validation or additional logic if needed
		handleSubmit(email);

		// Close the popup after collecting the email
		closePopup();
	};

	return (
		<div className="popup" id="popupForm">
			<div className="popup-content mt-10">
				<span className="close" onClick={closePopup}>
					&times;
				</span>
				<h4>Please provide your email to download the itinerary</h4>
				<ZohoCampaignItinerary />
				<button type="button" onClick={handleFormSubmit} className="mt-4">
					<span className="border-2 border-gray-800 rounded-full p-2 font-medium">
						<ArrowSmDownIcon className="h-4 w-4 inline-flex" /> Download Now
					</span>
				</button>
				{/* <form>
					<label htmlFor="email">Email:</label>
					<input
						type="email"
						id="email"
						name="email"
						value={email}
						onChange={handleEmailChange}
						required
					/>
					<button type="button" onClick={handleFormSubmit}>
						Subscribe and Print
					</button>
				</form> */}
			</div>
		</div>
	);
};

export default ItineraryPopupForm;
