import React from "react";
import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HotSlider = ({ slides }) => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 1000, // Adjust the speed as needed
		autoplay: true, // Enable automatic slideshow
		autoplaySpeed: 4000, // Set the time between slides in milliseconds
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
	};
	return (
		<div className="mb-6">
			<Slider {...settings}>
				{slides.map((slide) => {
					return (
						<div key={slide.id}>
							<GatsbyImage
								className="rounded-lg transform"
								image={slide.localFile.childImageSharp.gatsbyImageData}
								alt={slide.name.split("-").join(" ").split(".")[0]}
								// alt={slide.alternativeText}
								loading="lazy"
							/>
						</div>
					);
				})}
			</Slider>
		</div>
	);
};

export default HotSlider;
