import React, { useEffect } from "react";

const ZohoCampaignItinerary = () => {
	useEffect(() => {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.src = "https://campaigns.zoho.com/js/zc.iframe.js";
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<div className="relative pb-60 md:pb-44">
			<iframe
				className="absolute w-full h-full"
				title="Zoho Campaign"
				frameBorder="0"
				id="iframewin"
				width="100%"
				height="100%"
				src="https://srao-zgfh.maillist-manage.com/ua/Optin?od=11287ecbf3c7f4&zx=1695d6a0&tD=1d463ec8193437f9&sD=1d463ec8196c89f5"
			></iframe>
		</div>
	);
};

export default ZohoCampaignItinerary;
