import React from "react";
import Slider from "react-slick";
import Image from "~/components/image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HighlightSlider = ({ highlights }) => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 1000, // Adjust the speed as needed
		autoplay: true, // Enable automatic slideshow
		autoplaySpeed: 5000, // Set the time between slides in milliseconds
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
	};
	return (
		<div>
			<Slider {...settings}>
				{highlights.length > 0 &&
					highlights.map((light) => {
						return (
							<div key={light.id}>
								<div className="flex flex-col lg:flex-row  lg:items-center">
									<div className="lg:w-1/2 lg:mr-16 my-4">
										<Image
											className="rounded-md transform"
											image={light.image}
											alt="Highlight Image"
											loading="lazy"
										/>
									</div>
									<div className="lg:w-1/2 my-4">
										<h4 className="capitalize">{light.heading}</h4>
										<span>{light.desc}</span>
									</div>
								</div>
							</div>
						);
					})}
			</Slider>
		</div>
	);
};

export default HighlightSlider;
