import React from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Link,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
	page: {
		fontFamily: "Helvetica",
		fontSize: 12,
		padding: 20,
		backgroundColor: "#f7f7f7",
	},
	header: {
		marginBottom: 20,
		textAlign: "center",
		backgroundColor: "#EA5E48",
		padding: 10,
		borderRadius: 5,
	},
	headerText: {
		fontSize: 24,
		fontWeight: "bold",
		color: "#fff",
	},
	section: {
		marginBottom: 20,
		backgroundColor: "#fff",
		padding: 10,
		borderRadius: 5,
	},
	heading: {
		fontSize: 16,
		fontWeight: "bold",
		marginBottom: 10,
		textTransform: "uppercase",
		borderBottomWidth: 1,
		borderBottomColor: "#333",
		paddingBottom: 5,
	},
	content: {
		fontSize: 12,
		marginBottom: 10,
	},
	item: {
		marginBottom: 5,
	},
	abt: {
		marginTop: 10,
		color: "#FFFFFF",
		textDecoration: "none",
	},
	description: {
		marginBottom: 15,
	},
	url: {
		color: "blue",
		textDecoration: "none",
	},
});

const Itinerary = ({ tour }) => {
	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<View style={styles.header}>
					<Text style={styles.headerText}>Art of Bicycle Trips</Text>
					<Text style={styles.abt}>
						www.artofbicycletrips.com | contact@artofbicycletrips.com
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.heading}>
						<Link
							src={`https://artofbicycletrips.com/tours/${tour.slug}/`}
							style={styles.url}
							target="_blank"
							rel="noopener noreferrer"
						>
							{tour.title}
						</Link>
					</Text>
					<View style={styles.content}>
						<Text style={styles.item}>
							<Text style={{ fontWeight: "bold" }}>Duration:</Text> {tour.duration}
						</Text>
						<Text style={styles.item}>
							<Text style={{ fontWeight: "bold" }}>Price:</Text> From US${tour.price}
						</Text>
					</View>
				</View>
				{/* Quickfacts */}
				<View style={styles.section}>
					<Text style={styles.heading}>Quick Facts</Text>
					<Text style={styles.item}>
						<Text style={{ fontWeight: "bold" }}>Tour start:</Text>{" "}
						{tour.quickfacts.start}
					</Text>
					<Text style={styles.item}>
						<Text style={{ fontWeight: "bold" }}>Tour end:</Text>{" "}
						{tour.quickfacts.finish}
					</Text>
					<Text style={styles.item}>
						<Text style={{ fontWeight: "bold" }}>Lodging:</Text>{" "}
						{tour.quickfacts.lodging}
					</Text>
					<Text style={styles.item}>
						<Text style={{ fontWeight: "bold" }}>Level:</Text>{" "}
						{tour.quickfacts.level.split("_").join(" to ")}
					</Text>
					<Text style={styles.item}>
						<Text style={{ fontWeight: "bold" }}>Avg. dist. cycled:</Text>{" "}
						{tour.quickfacts.avgDistance}
					</Text>
					{tour.quickfacts.flyin && (
						<Text style={styles.item}>
							<Text style={{ fontWeight: "bold" }}>Fly-In:</Text>{" "}
							{tour.quickfacts.flyin}
						</Text>
					)}
					{tour.quickfacts.flyout && (
						<Text style={styles.item}>
							<Text style={{ fontWeight: "bold" }}>Fly-Out:</Text>{" "}
							{tour.quickfacts.flyout}
						</Text>
					)}
				</View>
				{/* Highlights */}
				<View style={styles.section}>
					<Text style={styles.heading}>Highlights</Text>
					{tour.highlight.map((node) => (
						<View key={node.id}>
							<Text style={styles.item}>- {node.desc}</Text>
						</View>
					))}
				</View>
				{/* Overview */}
				<View style={styles.section}>
					<Text style={styles.heading}>Overview</Text>
					<Text style={styles.item}>
						<Text style={{ fontWeight: "bold" }}>{tour.overview}</Text>
					</Text>
				</View>
				{/* Itinerary */}
				<View style={styles.section}>
					{/* <Text style={styles.heading}>Detailed Itinerary</Text> */}
					{tour.itinerary.map((node) => (
						<View key={node.id} style={styles.section}>
							<Text style={styles.heading}>
								Day {node.day} - {node.place}
							</Text>
							<View style={styles.content}>
								{node.desc && (
									<Text style={[styles.item, styles.description]}>
										<Text style={{ fontWeight: "bold" }}></Text> {node.desc}
									</Text>
								)}

								<Text style={styles.item}>
									<Text style={{ fontWeight: "bold" }}>Meals:</Text> {node.meals}
								</Text>
								<Text style={styles.item}>
									<Text style={{ fontWeight: "bold" }}>Cycling Distance:</Text>{" "}
									{node.cycDistance}
								</Text>
								{/* <Text style={styles.item}>
									<Text style={{ fontWeight: "bold" }}>Primary Activity:</Text>{" "}
									{node.activity}
								</Text> */}
								{/* {node.secActivity && (
									<Text style={styles.item}>
										<Text style={{ fontWeight: "bold" }}>Secondary Activity:</Text>{" "}
										{node.secActivity}
									</Text>
								)} */}
								{node.hotelUrl && (
									<Text style={styles.item}>
										<Text style={{ fontWeight: "bold" }}>Accommodation:</Text>{" "}
										<Link
											src={node.hotelUrl}
											style={styles.url}
											target="_blank"
											rel="noopener noreferrer"
										>
											{node.hotel}
										</Link>
									</Text>
								)}
							</View>
						</View>
					))}
				</View>
				{/* Weather */}
				{tour.weather && (
					<View style={styles.section}>
						<Text style={styles.heading}>Weather</Text>
						<Text style={styles.item}>
							<Text style={{ fontWeight: "bold" }}>{tour.weather}</Text>
						</Text>
					</View>
				)}
				{/* Visa */}
				{tour.visa && (
					<View style={styles.section}>
						<Text style={styles.heading}>Visa</Text>
						<Text style={styles.item}>
							<Text style={{ fontWeight: "bold" }}>{tour.visa}</Text>
						</Text>
					</View>
				)}
			</Page>
		</Document>
	);
};

export default Itinerary;
