import React from "react";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { Popover, Transition } from "@headlessui/react";
//import { Link } from "gatsby";
import { Fragment } from "react";

const TooltipLevel = () => {
	function classNames(...classes) {
		return classes.filter(Boolean).join(" ");
	}
	const lodging = [
		{
			name: "Easy",
			description: "I like biking on relatively flat to gently rolling roads.",
			//to: "/specialities/family",
			//icon: ChartBarIcon,
		},
		{
			name: "Moderate",
			description: "I enjoy biking on rolling terrain with a few moderate hills.",
			//to: "/specialities/day",
			//icon: CursorClickIcon,
		},
		{
			name: "Challenging",
			description: "I love biking on hillier and challenging terrain.",
			//to: "/specialities/day",
			//icon: CursorClickIcon,
		},
	];
	return (
		<Popover className="relative ml-1">
			{({ open }) => (
				<>
					<Popover.Button
						className={classNames(
							open ? "text-gray-900" : "text-primary",
							"group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
						)}
					>
						<InformationCircleIcon className="h-4 w-4 inline-flex" />
						{/* <ChevronDownIcon
							className={classNames(
								open ? "text-gray-600" : "text-gray-400",
								"ml-2 h-5 w-5 group-hover:text-gray-500"
							)}
							aria-hidden="true"
						/> */}
					</Popover.Button>

					<Transition
						show={open}
						as={Fragment}
						enter="transition ease-out duration-200"
						enterFrom="opacity-0 translate-y-1"
						enterTo="opacity-100 translate-y-0"
						leave="transition ease-in duration-150"
						leaveFrom="opacity-100 translate-y-0"
						leaveTo="opacity-0 translate-y-1"
					>
						<Popover.Panel
							static
							className="absolute z-10 -ml-4 mt-3 transform px-4 w-screen max-w-md -translate-x-20 sm:px-0 lg:ml-0 lg:left-1/2 sm:-translate-x-2/3"
						>
							<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
								<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
									{lodging.map((item) => (
										<div className="ml-4">
											<p className="text-base font-medium text-gray-900">{item.name}</p>
											<p className="mt-1 text-sm text-gray-500 lowercase">
												{item.description}
											</p>
										</div>
									))}
								</div>
							</div>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	);
};

export default TooltipLevel;
